import React, { useState,useEffect } from 'react'
import { toast } from 'react-toastify'
import Popup from '../../../components/Popup'
import Input from '../../../components/Input'
import EditImage from '../../../images/edit.svg'
import authAxios from '../../../helpers/axios'

export default function Preferences() {
    const [brands,setBrands] = useState([])
    const [showManage,setShowManage] = useState({status:false,edit:null})

    async function getBrands(){
        let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/brand`)
        setBrands(res.data)
    }

    useEffect(()=>
    {
        getBrands();
    },[])

    const onBrandSubmit = async(e,data)=>
    {
        try 
        {
            e.preventDefault()
            let res;
            console.log('sbm',data)
            if(showManage.edit)
            {
                res = await authAxios.put(`/brand/${data.id}`,{...data})  
            }
            else
            {
                res = await authAxios.post(`/brand`,{...data}) 
                
            }
            if(res.data)
            {
                if(showManage.edit)
                {
                    setBrands(brands=>{
                        let prev = [...brands]
                        let itemIndex = prev.findIndex(item=>item.id === showManage.edit)
                        console.log('itemIndex',itemIndex,'res',res.data)
                        prev[itemIndex] = {...prev[itemIndex],name:res.data.name}
                        return prev;
                    })
                }
                else setBrands(brands=>([...brands,{...res.data}]))
                toast.success(showManage.edit ? 'Brand Updated' : 'City Created',{position: toast.POSITION.BOTTOM_CENTER,hideProgressBar:true})
                await getBrands()
                setShowManage({status:false,edit:null})
            }
            else toast('error creating Brand')
        } catch (error) {
            console.log(error)
            toast.error(error)
        }
    }

  return (
    <div>
        <div>
        <DriverPricing/>
        <PickupDropFee/>
    </div>
    {showManage.status === true ? <ManageBrand setShow={setShowManage} onSubmit={onBrandSubmit} edit={showManage.edit}/> : null}
    </div>
  )
}

const DriverPricing = ()=>
{
    return <div className='bg-white border border-gray-200 overflow-hidden rounded-md shadow-sm'> 
        <div className='flex justify-between items-center px-6 py-4'>
            <div>
                <p className='text-base font-semibold'>Driver Fee</p>
                <p className='text-xs text-[#757575]'>Driver Fee Option provides flexibility and convenience for customers using our car rental platform. </p>
            </div>
            <div>
                <input type='text' value={'800'} disabled={true} className='py-2 px-4 border rounded-md border-gray-200 w-[120px]'/>
            </div>
        </div>
    </div>
}

const PickupDropFee = ()=>
{
    return <div className='bg-white border border-gray-200 overflow-hidden rounded-md mt-4 shadow-sm'> 
        <div className='flex justify-between items-center px-6 py-4'>
            <div>
                <p className='text-base font-semibold'>Pickup & Drop Fee</p>
                <p className='text-xs text-[#757575]'>Pickup & Drop Fee Option provides flexibility and convenience for customers using our car rental platform. </p>
            </div>
            <div>
                <input type='text' value={'500'} disabled={true} className='py-2 px-4 border rounded-md border-gray-200 w-[120px]'/>
            </div>
        </div>
    </div>
}


const ManageBrand = ({setShow,onSubmit,edit=false})=>
{
    const [brand,setBrand] = useState({id:null,name:''})
    const [loading,setLoading] = useState(edit ? true : false)
    useEffect(()=>
    {
        async function getBrandInfo(){
            if(edit)
            {
                let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/brand/${edit}`)
                console.log(res.data.data)
                setBrand({id:res.data.data.id,name:res.data.data.name})
                setLoading(false)
            }
        }
        getBrandInfo()
    },[])
    return loading ? 'loading' :<Popup onClose={()=>setShow({status:false})}  title={edit ?  'Edit Brand' : 'Add Brand'} submitTitle={edit ? 'Update' : 'Add'} formName={'createCategory'}>
        <form className='w-full' onSubmit={(e)=>onSubmit(e,edit ? brand : {name:brand.name})} id="createBrand">
            <div>
                <label>Brand Name</label>
                <Input placeholder={'Enter Brand Name'} value={brand.name} setValue={(value)=>setBrand(brand=>({...brand,name:value}))} required={true}/>
            </div>
        </form>
    </Popup>
}