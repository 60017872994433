import React, { useEffect, useState } from 'react'
import authAxios from '../../helpers/axios'
import { Link, Outlet, useParams } from 'react-router-dom'
import Loader from '../../components/Loader'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/react-splide/css';

export default function VehicleInformation() {

    const {id} = useParams()
    const [data,setData] = useState({})
    const [loading,setLoading] = useState(true)
    const [showManage,setShowManage] = useState({type:null,status:false,edit:null})
    // // const [showEdit]
    async function getVehicleInformation(){
        let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/vehicle/${id}`)
        setData(res.data)
        setLoading(false)
    }

    useEffect(()=>
    {
        getVehicleInformation();
    },[])


  return (
    !loading ? <div>
        <div className='bg-white rounded-md shadow-sm px-6 py-2'>
            <p className='text-sm font-medium text-[#959595] pb-3 border-b border-b-slate-200'>Vehicle Basic Information</p>
            <div className='grid grid-cols-4 gap-x-3 mt-8 gap-y-4 py-4'>
                <div>
                    <label>Vehicle Number</label>
                    <p className='text-sm font-medium'>{data.vehicleNumber}</p>
                </div>
                <div>
                    <label>Vehicle Model</label>
                    <p className='text-sm font-medium'>{data.vehicleName}</p>
                </div>
                <div>
                    <label>Vehicle Brand</label>
                    <p className='text-sm font-medium'>{data.brand.name}</p>
                </div>
                <div>
                    <label>Model Year</label>
                    <p className='text-sm font-medium'>{data.vehicleYear}</p>
                </div>
                <div>
                    <label>Owner Type</label>
                    <p className='text-sm font-medium'>{data.ownerType === 1 ? 'Vendor' : 'Company Owned'}</p>
                </div>
                <div>
                    <label>Vehicle Type</label>
                    <p className='text-sm font-medium caps'>{data.vehicleType}</p>
                </div>
                <div>
                    <label>Vehicle Seats</label>
                    <p className='text-sm font-medium'>{data.vehicleSeats} Seats</p>
                </div>
                <div>
                    <label>Owner Fuel Type</label>
                    <p className='text-sm font-medium'>{data.vehicleFuelType}</p>
                </div>
            </div>
        </div>
        <div className='bg-white rounded-md shadow-sm px-6 py-4 mt-4'>
            <Splide options={ {autoWidth:false,gap:'24px',arrows:false,perPage:3} } aria-label="React Splide Example">
                {
                  data.images.map((item,index)=>
                  {
                    return <SplideSlide key={index}><img src={item.url} className='rounded-md'/></SplideSlide>
                  })
                }
              </Splide>
        </div>

        <div className='bg-white rounded-md shadow-sm px-6 py-4 mt-4'>
            <div className='pb-3 border-b border-b-slate-200 flex w-full justify-between'>
            <p className='text-sm font-medium text-[#959595] '>Vehicle Plan Information</p>
            <button className='btn-xs'>Update Plan</button>
            </div>
            <div className='grid grid-cols-4 gap-x-3 mt-8 gap-y-3'>
                <div>
                    <label>Per Hour Fee</label>
                    <p className='text-sm font-medium'>{data.vehiclePlan[0].perHourFee}</p>
                </div>
                <div>
                    <label>Distance Alloted</label>
                    <p className='text-sm font-medium'>{data.vehiclePlan[0].kmAlloted}</p>
                </div>
                <div>
                    <label>Extra Km Fee</label>
                    <p className='text-sm font-medium'>{data.vehiclePlan[0].extraKmFee}</p>
                </div>
            </div>
        </div>
    </div> : <Loader/>
  )
}
