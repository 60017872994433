import React, { useEffect, useState } from 'react'
import RightIcon from '../../images/right.svg'
// import SearchInput from '../../components/SearchInput'
import authAxios from '../../helpers/axios'
import { toast } from 'react-toastify'
import { Link, useNavigate } from 'react-router-dom'
import { InfoToast } from '../../helpers/toasters'
import { getDateFormat, getTimeFormat, getValidDateFormat } from '../../helpers/utils'
import { LIMIT } from '../../helpers/constants'
import SearchInput from '../../components/SearchInput'

export default function Users() {

    const [searchText,setSearchText] = useState('')
    const [users,setUsers] = useState([])
    const [showCreate,setShowCreate] = useState({status:false,edit:null})
    const [offset,setOffset] = useState(0);
    const [count,setCount] = useState(5)
    const [cityFilters,setCityFilters] = useState([])
    const [routeFilters,setRouteFilters] = useState([])
    const navigate = useNavigate()
    

    const [selectedFilters,setSelectedFilters] = useState({city:'',route:''})
    const RightContent = ()=>
    {
        return null
    }

    async function getUsers(){

        try 
        {
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/user?populate=true`)
            console.log('data',res.data)
            if(res.data) setUsers(res.data)
        } catch (error) {
            toast('Error getting products')
        }
    }
    useEffect(()=>
    {
        getUsers();
    },[])




  return (
    <>
        <div className='block justify-between max-w-7xl mx-auto'>
        <div className='flex mb-4 justify-between '>
            <SearchInput value={searchText} setValue={(value)=>setSearchText(value)} placeholder={'Search vendor'} label={'Search vendor'} />
            <div className='flex items-center h-full justify-center text-center border-[#d3d3d3] bg-white rounded-md shadow-sm shadow-gray-200 ml-4'>
                <div
                    className='px-2 hover:bg-[#f6f6f6] h-full cursor-pointer flex items-center flex-1 justify-center'
                    onClick={() => {
                    if (offset > 0) {
                        setOffset(offset - LIMIT);
                    }
                    }}
                >
                    <img src={RightIcon} className='w-[20px] h-[20px] rotate-180' alt='Previous Page' />
                </div>
                <p className='text-xs whitespace-nowrap w-[80px] justify-center'>{offset + 1}-{Math.min(offset + LIMIT, count)} of {count}</p>
                <div
                    className='px-2 py-2 hover:bg-[#f6f6f6] h-full cursor-pointer flex items-center flex-1 justify-center'
                    onClick={() => {
                    if (offset + LIMIT < count) {
                        setOffset(offset + LIMIT);
                    }
                    }}
                >
                    <img src={RightIcon} className='w-[20px] h-[20px]' alt='Next Page' />
                </div>
                </div>
        </div>
        <div className='flex flex-1 bg-white w-full'>
            <table className=' w-full'>
                <thead className='bg-[#f9f9f9] w-full'>
                    <tr className='w-full'>
                    {/* <td><p>Image</p></td> */}
                    <td><p>Name</p></td>
                    <td><p>Emai/Phone</p></td>
                    <td><p>Joined On</p></td>
                    <td><p>Status</p></td>
                    </tr>
                </thead>
                <tbody>
                    {
                        users.map((item,index)=>
                        {
                            return <tr key={index} onClick={()=>navigate(`/users/${item.id}/`)}>
                                <td className='capitalize'>
                                <div className='flex w-full'>
                                    <div className='mr-3  w-10 h-10'>
                                        {item.profilePhoto ? <img src={item.profilePhoto} className='w-10 h-10 rounded-full'/> : <div className='bg-gray-400 rounded-full h-10 w-10'></div>}
                                    </div>
                                    <div>
                                        <p className='text-sm font-medium my-0'>{item.name ? item.name : 'Unavailable'}</p>
                                        <p className='text-xs my-0 text-gray-400'>{getDateFormat(item.createdAt)}</p>
                                    </div>
                                    </div>
                                </td>
                                <td>
                                <div>
                                        <p className='text-sm font-regular my-0'>{item.email ? item.email : 'Not Available'}</p>
                                        <p className='text-xs font-regular my-0'>{item.contactNumber ? item.contactNumber : 'Not Available'}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>{`${getValidDateFormat(item.createdAt)}`}</p>
                                        <p className='text-xs font-regular my-0'>{getTimeFormat(item.createdAt)}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                    {/* <button className='btn-xs' onClick={()=>setShowCreate({status:true,edit:item.id})}>Edit</button> */}
                                    </div>
                                </td>
                            </tr>
                        })
                    }
                </tbody>
            </table>
        </div>
        </div>
    </>
  )
}

