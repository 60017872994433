import React, { useEffect, useState } from 'react'
// import { useNavigate } from 'react-router-dom'
import Header from '../../components/Header'
import RightIcon from '../../images/right.svg'
import authAxios from '../../helpers/axios'
import { toast } from 'react-toastify'
import { InfoToast } from '../../helpers/toasters'
import SearchInput from '../../components/SearchInput'
import { LIMIT } from '../../helpers/constants'
import { useParams, useSearchParams } from 'react-router-dom'
import { getDateFormat } from '../../helpers/utils'
import Loader from '../../components/Loader'

export default function RideInfo() {

    const {id} = useParams()
    const [rideInfo,setRideInfo] = useState([])
    const [showCreate,setShowCreate] = useState({status:false,edit:null})
    const [loading,setLoading] = useState(true)


    async function getRideInfo(){

        try 
        {
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/booking/${id}?populate=true`)
            console.log('data',res.data)
            if(res.data) setRideInfo(res.data)
            setLoading(false)
        } catch (error) {
          setLoading(false)
          toast('Error getting products')
        }
    }
    useEffect(()=>
    {
        getRideInfo();
    },[])




  return (
    !loading ? <div className='max-w-7xl mx-auto px-6'>

        <div className='bg-white rounded-md shadow-sm px-6 py-4 mt-4'>
            <div className='pb-3 border-b border-b-slate-200 flex w-full justify-between'>
            <p className='text-sm font-medium text-[#959595] '>Ride Information</p>
            <button className='btn-xs'>Start Ride</button>
            </div>
        <div className='grid grid-cols-4 gap-6 pt-6'>
            <div>
                <label>Total Rides</label>
                <p className='text-sm'>{rideInfo.bookingId}</p>
            </div>
            <div>
                <label>Total Clean Rides</label>
                <p className='text-sm'>{getDateFormat(rideInfo.startTime)}</p>
            </div>
            <div>
                <label>Deposit Free</label>
                <p className='text-sm'>{rideInfo.isFreeDepositAllowed ? 'Allowed' : 'Not Allowed'}</p>
            </div>
            </div>
        </div>
        
        
        <div className='bg-white rounded-md shadow-sm px-6 py-4 mt-4'>
            <div className='pb-3 border-b border-b-slate-200 flex w-full justify-between'>
            <p className='text-sm font-medium text-[#959595] '>Vehicle Information</p>
            </div>
        <div className='grid grid-cols-4 gap-6 pt-6'>
            <div>
                <label>Vehicle Brand / Name</label>
                <p className='text-sm'>{`${rideInfo.vehicle.brand.name} - ${rideInfo.vehicle.vehicleName}`}</p>
            </div>
            <div>
                <label>Vehicle Number</label>
                <p className='text-sm'>{rideInfo.vehicle.vehicleNumber ? rideInfo.vehicle.vehicleNumber : 'Not Available'}</p>
            </div>
            <div>
                <label>Deposit Free</label>
                <p className='text-sm'>{rideInfo.isFreeDepositAllowed ? 'Allowed' : 'Not Allowed'}</p>
            </div>
            <div>
                <label>Vehicle Plan</label>
                <p className='text-sm'>Rs.{rideInfo.vehicle.vehiclePlan[0].perHourFee}</p>
            </div>
            </div>
        </div>


        <div className='bg-white rounded-md shadow-sm px-6 py-4 mt-4'>
            <div className='pb-3 border-b border-b-slate-200 flex w-full justify-between'>
            <p className='text-sm font-medium text-[#959595] '>Payment Information</p>
            </div>
        <div className='grid grid-cols-4 gap-6 pt-6'>
            <div>
                <label>Payment Id</label>
                <p className='text-sm'>{rideInfo.payment.id}</p>
            </div>
            <div>
                <label>Payment Method</label>
                <p className='text-sm uppercase'>{rideInfo.payment.method}</p>
            </div>
            <div>
                <label>Payment Status</label>
                <p className='text-sm uppercase'>{rideInfo.payment.status}</p>
            </div>
            </div>
        </div>
        {/* { showCreate.status ? <ManageProduct onClose={setShowCreate} onSubmit={onSubmit} edit={showCreate.edit}/> : null} */}
    </div> : <Loader/>
  )
}

