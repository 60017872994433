import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Header from '../../components/Header'
import RightIcon from '../../images/right.svg'
import authAxios from '../../helpers/axios'
import { toast } from 'react-toastify'
import { InfoToast } from '../../helpers/toasters'
import SearchInput from '../../components/SearchInput'
import { LIMIT } from '../../helpers/constants'
import { getDateFormat, getTimeFormat, getValidDateFormat } from '../../helpers/utils'

export default function Membership() {

    const [searchText,setSearchText] = useState('')
    const [rides,setMemberships] = useState([])
    const [showCreate,setShowCreate] = useState({status:false,edit:null})
    const [offset,setOffset] = useState(0);
    const [count,setCount] = useState(5)
    const [cityFilters,setCityFilters] = useState([])
    const [routeFilters,setRouteFilters] = useState([])
    const navigate = useNavigate()
    

    const [selectedFilters,setSelectedFilters] = useState({city:'',route:''})
    const RightContent = ()=>
    {
        return null
    }

    async function getMemberships(){

        try 
        {
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/membership?populate=true`)
            console.log('data',res.data)
            if(res.data) setMemberships(res.data)
        } catch (error) {
            toast('Error getting products')
        }
    }
    useEffect(()=>
    {
        getMemberships();
    },[])


  return (
    <div className='max-w-7xl mx-auto px-6'>
            <div className='flex justify-between items-stretch '>
        <div className='flex mb-4'>
            <SearchInput value={searchText} setValue={(value)=>setSearchText(value)} placeholder={'Search membership'} label={'Search Membership'} />
            <div className='flex w-full items-center h-full justify-center text-center border-[#d3d3d3] bg-white rounded-md shadow-sm shadow-gray-200 ml-4'>
                <div
                    className='px-2 hover:bg-[#f6f6f6] h-full cursor-pointer flex items-center flex-1 justify-center'
                    onClick={() => {
                    if (offset > 0) {
                        setOffset(offset - LIMIT);
                    }
                    }}
                >
                    <img src={RightIcon} className='w-[20px] h-[20px] rotate-180' alt='Previous Page' />
                </div>
                <p className='text-xs whitespace-nowrap w-[80px] justify-center'>{offset + 1}-{Math.min(offset + LIMIT, count)} of {count}</p>
                <div
                    className='px-2 py-2 hover:bg-[#f6f6f6] h-full cursor-pointer flex items-center flex-1 justify-center'
                    onClick={() => {
                    if (offset + LIMIT < count) {
                        setOffset(offset + LIMIT);
                    }
                    }}
                >
                    <img src={RightIcon} className='w-[20px] h-[20px]' alt='Next Page' />
                </div>
                </div>
        </div>
    </div>
        <div className='flex bg-white rounded-md flex-1  w-full'>
        <table className=' w-full'>
                <thead className='bg-[#f9f9f9] w-full'>
                    <tr className='w-full'>
                    <td><p>User</p></td>
                    <td><p>Order Id / Payment Id</p></td>
                    <td><p>Start Date</p></td>
                    <td><p>End Date</p></td>
                    <td><p>Amount</p></td>
                    <td><p>Status</p></td>
                    </tr>
                </thead>
                <tbody>
                    {
                        rides.map((item,index)=>
                        {
                            return <tr>
                                <td className='capitalize'>
                                    <div>
                                         <p className='text-sm font-regular my-0'>{item.user.name}</p>
                                         <p className='text-xs my-0 text-gray-400'>{item.user.contactNumber}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>{item.transaction.orderId}</p>
                                        <p className='text-xs my-0 text-gray-400'>{item.transaction.paymentId}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                    <div>
                                         <p className='text-sm font-regular my-0'>{`${getDateFormat(item.startingTime)}`}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                         <p className='text-sm font-regular my-0'>{`${getDateFormat(item.endingTime)}`}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>Rs. {item.amount}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>{item.status}</p>
                                    </div>
                                </td>

                            </tr>
                        })
                    }
                </tbody>
            </table>
        </div>
        {/* { showCreate.status ? <ManageProduct onClose={setShowCreate} onSubmit={onSubmit} edit={showCreate.edit}/> : null} */}
    </div>
  )
}

