import { useEffect, useState } from "react"
import Input from "../../../components/Input"
import Popup from "../../../components/Popup"
import Select from "../../../components/Select"
import  authAxios  from "../../../helpers/axios"
import SlidePopup from "../../../components/SlidePopup"
import SingleImageUploader from "../../../components/SingleImageUploader"
import ImageUploader from "../../../components/ImageUploader"
import FullPopup from "../../../components/FullPopup"
import CloseIcon from "../../../images/close.svg"
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import { CKEditor } from "@ckeditor/ckeditor5-react"

export default function ManageVehicle({onClose,onSubmit,updateData,edit})
{
    const [step,setStep] = useState(false)
    const [manageData,setManageData] = useState({vehicleName:'',vehicleYear:'',vehicleBrand:'',vehicleType:1,ownerType:'',ownerId:'',city:'',vehicleFuelType:'',plan:{perHourFee:'',kmAlloted:'',extraKmFee:'',description:'',vehicleNumber:''}})
    const [cities,setCities] = useState([])
    const [salesRep,setSalesRep] = useState([])
    const [showCrop,setShowCrop] = useState(false)
    const formName = 'manageVehicle';
    const [images,setImages] = useState([])
    const [image,setImage] = useState(null)
    const [croppedImage,setCroppedImage] = useState()
    const [brands,setBrands] = useState([])
    const [vendors,setVendors] = useState([])
    const [types,setTypes] = useState([{id:'hatchback',value:'Hatchback'},{id:'sedan',value:'Sedan'},{id:'csuv',value:'Compact SUV'},{id:'suv',value:'SUV'}])
    const [pickups,setPickups] = useState([])

    // const onImageChange = (event) => {
    //     if (event.target.files && event.target.files[0]) {
    //         setShowCrop(true)
    //     setImage(URL.createObjectURL(event.target.files[0]));
    //     }
    //     }

    useEffect(()=>
    {
        async function getCities(){
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/city`)
            setCities(res.data)
        }
        async function getBrands(){
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/brand`)
            setBrands(res.data)
        }
        async function getVendors(){
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/vendor`)
            setVendors(res.data)
        }
        async function getPickups(){
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/pickup-point`)
            setPickups(res.data.rows)
        }
        getCities()
        getBrands()
        getVendors()
        getPickups()
    },[])

    // useEffect(()=>
    // {
    //     async function getRoutes(){
    //         let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/salesrepresentative?repCity=${manageData.cityId}`)
    //         setSalesRep(res.data.data)
    //     }
    //     if(manageData.cityId !== '') getRoutes()
    // },[manageData.cityId])



    return <>
        <div className='bg-[rgba(255,255,255,0.99)] backdrop-blur-sm bg-opacity-70 fixed z-[999] w-[100%] h-full left-0 top-0 flex justify-center items-center overflow-scroll py-2'>
    <div className=' mx-auto w-full h-full  overflow-hidden flex flex-col'>
        <div className='flex px-8 py-4 justify-between items-center border-b-2 border-gray-100 bg-[#fff]'>
            <div className='flex justify-between max-w-2xl w-full mx-auto pr-10'>
                <div>
                    {/* <p className='text-xs font-medium text-[#757575] tracking-[-.15px] capitalize'>Create Vehicle</p> */}
                    <h3 className='text-[14px] font-semibold tracking-[-.15px] capitalize'>Create Vehicle</h3>
                </div>
                {/* <div>
                    <p className='text-xs font-medium text-[#757575] tracking-[-.15px] capitalize'>Step:2</p>
                    <h3 className='text-[14px] font-semibold tracking-[-.15px] capitalize'>Upload Images</h3>
                </div>
                <div>
                    <p className='text-xs font-medium text-[#757575] tracking-[-.15px] capitalize'>Step:3</p>
                    <h3 className='text-[14px] font-semibold tracking-[-.15px] capitalize'>Features</h3>
                </div> */}
            </div>
        </div>
        <div className='flex flex-1 items-start px-8 py-6 w-full overflow-scroll'>
        <form onSubmit={(e)=>onSubmit(e,{...manageData},images)} id={formName} className='max-w-2xl mx-auto  w-full'>
            <div className="border-b border-b-slate-200 grid grid-cols-6 gap-x-3 gap-y-0 py-6">
            <div className="col-span-2">
                <label>Vehicle Name*</label>
                <Input type='text' placeholder={'Enter Vehicle Name'} value={manageData.vehicleName} required={true} setValue={(value)=>setManageData(data=>({...data,vehicleName:value}))}/>
            </div>
            <div className="col-span-2">
                <label>City*</label>
                <Select placeholder={'Select City'} customLabel={'name'} customValue={'id'} options={cities}  value={manageData.city} setValue={(value)=>setManageData(data=>({...data,city:value}))}/>
            </div>
            <div className="col-span-2">
                <label>Fuel Type*</label>
                <Select placeholder={'Select Fuel Type'} customLabel={'name'} customValue={'id'} options={[{name:'Petrol',value:0},{name:'Diesel',value:1},{name:'Electric',value:2}]}  value={manageData.vehicleFuelType} setValue={(value)=>setManageData(data=>({...data,vehicleFuelType:value}))}/>
            </div>
            <div className="col-span-2">
                <label>Vehicle Model Year*</label>
                <Input number={true} type='text' placeholder={'Enter Model Year'} value={manageData.vehicleYear} required={true} setValue={(value)=>setManageData(data=>({...data,vehicleYear:value}))}/>
            </div>
            <div className="col-span-2">
                <label>Vehicle Brand*</label>
                <Select placeholder={'Select Brand'} customLabel={'name'} customValue={'id'} options={brands}  value={manageData.vehicleBrand} setValue={(value)=>setManageData(data=>({...data,vehicleBrand:value}))}/>
            </div>
            <div className="col-span-2">
                <label>Vehicle Type*</label>
                <Select placeholder={'Select Vehicle Type'} customLabel={'value'} customValue={'id'} options={types}  value={manageData.vehicleType} setValue={(value)=>setManageData(data=>({...data,vehicleType:value}))}/>
            </div>
            <div className="col-span-3">
                <label>Owner Type*</label>
                <Select placeholder={'Select Owner Type'} options={[{name:'Owner',value:0},{name:'Vendor',value:1}]}  value={manageData.ownerType} setValue={(value)=>setManageData(data=>({...data,ownerType:value}))}/>
            </div>
            {manageData.ownerType === 1 ? <div className="col-span-3">
                <label>Vendor*</label>
                <Select viewMode={manageData.ownerType === 1 ? true : false} customLabel={'name'} customValue={'id'} placeholder={'Select Vendor'} options={vendors}  value={manageData.ownerId} setValue={(value)=>setManageData(data=>({...data,ownerId:value}))}/>
            </div> : <div className="col-span-3"></div>}
            <div className="col-span-2">
                <label>Vehicle Number</label>
                <Input number={true} type='text' placeholder={'Eg., TG-10BS-1213'} value={manageData.vehicleNumber} required={true} setValue={(value)=>setManageData(data=>({...data,vehicleNumber:value}))}/>
            </div>
            <div className="col-span-2">
                <label>Vehicle Pickup Point</label>
                <Select placeholder={'Select Pickup Point'} customLabel={'name'} customValue={'id'} options={pickups}  value={manageData.pickupId} setValue={(value)=>setManageData(data=>({...data,pickupId:value}))}/>
            </div>
            <div className="col-span-2">
                <label>Vehicle Transmission</label>
                <Select placeholder={'Select Transmission Type'} options={[{name:'Manual',value:'manual'},{name:'Automatic',value:'automatic'}]}  value={manageData.vehicleTransmission} setValue={(value)=>setManageData(data=>({...data,vehicleTransmission:value}))}/>
            </div>
            </div>

            <div className="grid grid-cols-6 gap-x-3 gap-y-0 w-full py-6 border-b border-b-slate-200">
                <div className="col-span-2">
                    <label>Per Hour Fee *</label>
                    <Input number={true} type='text' placeholder={'Per Hour Fee'} value={manageData.plan.perHourFee} required={true} setValue={(value)=>setManageData(data=>({...data,plan:{...data.plan,perHourFee:value}}))}/>
                </div>
                <div className="col-span-2">
                    <label>Alloted Kms *</label>
                    <Input number={true} type='text' placeholder={'Alloted Km'} value={manageData.plan.kmAlloted} required={true} setValue={(value)=>setManageData(data=>({...data,plan:{...data.plan,kmAlloted:value}}))}/>
                </div>
                <div className="col-span-2">
                    <label>Extra Km Fee *</label>
                    <Input number={true} type='text' placeholder={'Extra Km Fee'} value={manageData.plan.extraKmFee} required={true} setValue={(value)=>setManageData(data=>({...data,plan:{...data.plan,extraKmFee:value}}))}/>
                </div>
            </div>

            <div className="w-full py-6 border-b border-b-slate-200">
                <label>Vehicle Images</label>
                <ImageUploader images={images} setImages={setImages}/>
            </div>

            <div className="py-6 border-b border-b-slate-200">
                <label className="mb-2">Vehicle Description</label>
                <CKEditor
                editor={ ClassicEditor }
                data={manageData.description}
                onReady={ ( editor ) => {
                console.log( "CKEditor5 React Component is ready to use!", editor );
                } }
                onChange={ ( event, editor ) => {
                const data = editor.getData();
                console.log('data',data)
                setManageData(prev=>({...prev,description:data}))
                } }
            />
            </div>
        </form>
        </div>
        <div className='flex justify-end mt-4 px-8 py-4  bg-[#fafafa] border-t-2 border-gray-50'>
            <button type='button' className='btn-md-disabled' onClick={()=>onClose(false)}>Cancel</button>
            <button form={formName} type='submit' className='ml-4 btn-md'>Continue</button>
        </div>
    </div>
</div>
    {/* {showCrop ? <CropPopup image={image} setActive={setShowCrop} setImage={setCroppedImage}/>: null} */}
    </>
}