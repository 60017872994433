import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
import authAxios from '../../helpers/axios'
import { toast } from 'react-toastify'
import { Link, NavLink, Outlet, useParams } from 'react-router-dom'
import TabGroup from '../../components/TabGroup'
import UserRides from './UserRides'
import UserPayments from './UserPayments'
import { Auth } from 'firebase/auth'
// import ManageRep from './components/ManageRep'
// import SalesOrder from './components/SalesOrder'
// import SalesRoute from './components/SalesRoute'

export default function UserInfo() { 

    const {id} = useParams();
    const menu = [{label:`Rides`,url:`/users/${id}/`},{label:`Payments`,url:`/users/${id}/payments`},{label:`Profile`,url:`/users/${id}/profile`}]
    // const id = 'd48a0ac8-0aac-ce9f-2f43-b0823a8458d1'
    const [userInfo,setUserInfo] = useState([])
    const [showEdit,setShowEdit] = useState(false)
    const [activeTab,setActiveTab] = useState('rides')
    const [submitting,setSubmitting] = useState(false)

    const RightContent = ()=>
    {
        return <div>
          <button type='button' className='btn-md' onClick={()=>setShowEdit(true)}>Edit User</button>
        </div>
    }


  return (
    <>
        {/* <Header title={userInfo.name} RightContent={RightContent} parent='Users' parentLink='users'/> */}
        <div className='block grid-cols-10 justify-between items-stretch max-w-7xl mx-auto'>
               <TabGroup options={menu}/>
        <div className=' col-span-8 flex overflow-scroll max-w-7xl bg-white '>
            <Outlet context={{id:id}}/>
        </div>
        </div>
        {/* { showEdit ? <ManageRep submitting={submitting} onClose={setShowEdit} onSubmit={onSubmit} edit={id} updateData={repInfo}/> : null} */}
    </>
  )
}

// {activeTab === 'rides' ? <UserRides id={id} /> : activeTab === 'payments' ? <UserPayments id={id}/> : <ProfileInfo id={id}/>}
const MenuItem = ({item})=>
{
    return <NavLink to={item.url} end className={({isActive}) => `block items-center py-2 px-4 bg-white border-r border-r-slate-200 ${isActive ? 'bg-[black] text-[#ECC032]' : 'bg-transparent text-[#454545]'}`}>
        <div className='flex items-center text-center'>
            <p className='text-sm font-semibold tracking-tight '>{item.label}</p>
        </div>
        </NavLink>
}


