import React, { useEffect, useState } from 'react'
import Header from '../../components/Header'
// import SearchInput from '../../components/SearchInput'
import authAxios from '../../helpers/axios'
import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import { InfoToast } from '../../helpers/toasters'
import { getDateFormat, getTimeFormat, getValidDateFormat } from '../../helpers/utils'

export default function UserRides() {

    const [searchText,setSearchText] = useState('')
    const [rides,setRides] = useState([])
    const [showCreate,setShowCreate] = useState({status:false,edit:null})
    const {id} = useParams()
    const [cityFilters,setCityFilters] = useState([])
    const [routeFilters,setRouteFilters] = useState([])
    const navigate = useNavigate()
    const [activeRide,setActiveRide] = useState(false)
    

    const [selectedFilters,setSelectedFilters] = useState({city:'',route:''})
    const RightContent = ()=>
    {
        return null
    }

    async function getRides(){

        try 
        {
            let res = await authAxios.get(`${process.env.REACT_APP_BASE_URL}/booking/user/${id}?populate=true`)
            console.log('data',res.data)
            if(res.data) setRides(res.data)
        } catch (error) {
            toast('Error getting products')
        }
    }
    useEffect(()=>
    {
        getRides();
    },[])




  return (
    <>
        {/* <div className='flex-auto rounded-md flex justify-between items-stretch border-0  max-w-7xl p'> */}
            {/* <div>
                <SearchInput value={searchText} setValue={(value)=>setSearchText(value)} placeholder={'Search Product'} label={'Search Product'} />
            </div> */}
            {/* <div className='flex justify-end items-stretch h-[100%] self-stretch'>
                <div className='h-[100%]'>
                    <FilterSelect placeholder={'Select city'} options={cityFilters} value={selectedFilters.city} customLabel={'cityName'} customValue={'id'} setValue={(value)=>setSelectedFilters(data=>({...data,city:value}))} padding={false}/>
                </div>
            <div>
                <FilterSelect placeholder={'Select route'} options={routeFilters} value={selectedFilters.route} customLabel={'routeName'} customValue={'id'} setValue={(value)=>setSelectedFilters(data=>({...data,route:value}))} padding={false}/>
            </div>
            </div> */}
        {/* </div> */}
        <div className='flex flex-1  w-full '>
            <table className=' w-full'>
                <thead className='bg-[#f9f9f9] w-full'>
                    <tr className='w-full'>
                    <td><p>Vehicle</p></td>
                    <td><p>Start Time</p></td>
                    <td><p>End Time</p></td>
                    <td><p>Amount(In Rs.)</p></td>
                    <td><p>Status</p></td>
                    </tr>
                </thead>
                <tbody>
                    {
                        rides.map((item,index)=>
                        {
                            return <><tr onClick={()=>setActiveRide(item.id)}>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>{item.vehicle.vehicleName}</p>
                                        <p className='text-xs my-0 text-gray-400'>{item.vehicle?.vehicleNumber}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                    <div>
                                        <p className='text-sm font-regular my-0'>{item.startTime ? `${getDateFormat(item.startTime)}` :  'Unavailable'}</p>
                                        <p className='text-xs my-0 text-gray-400'>{getTimeFormat(item.startTime)}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                    <div>
                                        <p className='text-sm font-regular my-0'>{item.endTime ? `${getDateFormat(item.endTime)}` :  'Unavailable'}</p>
                                        <p className='text-xs my-0 text-gray-400'>{getTimeFormat(item.endTime)}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-regular my-0'>{item.totalAmount}</p>
                                    </div>
                                </td>
                                <td className='capitalize'>
                                <div>
                                        <p className='text-sm font-semibold my-0 uppercase'>{item.status}</p>
                                    </div>
                                </td>
                            </tr>
                                {activeRide === item.id ? <tr className='cols' colSpan={5}>
                                    <div className='bg-white px-6 py-4 w-full'>
                                        <p className='text-sm font-semibold my-0 mb-4'>Ride Information</p>
                                        <div className='grid grid-cols-4 w-full'>
                                            <div className='grid grid-cols-2 gap-y-3 gap-x-3 border-r border-r-gray-200'>
                                                <div>
                                                    <p className='text-xs my-0 text-gray-400'>Start Time</p>
                                                    <p className='text-xs font-medium tracking-tight my-0'>{getDateFormat(item.startTime)}</p>
                                                </div>
                                                <div>
                                                    <p className='text-xs my-0 text-gray-400'>End Time</p>
                                                    <p className='text-xs font-medium tracking-tight my-0'>{getDateFormat(item.endTime)}</p>
                                                </div>
                                                <div>
                                                    <p className='text-xs my-0 text-gray-400'>Pickup Time</p>
                                                    <p className='text-xs font-medium tracking-tight my-0'>{item.pickupTime ? getDateFormat(item.pickupTime) : 'Not Available'}</p>
                                                </div>
                                                <div>
                                                    <p className='text-xs my-0 text-gray-400'>Drop Time</p>
                                                    <p className='text-xs font-medium tracking-tight my-0'>{item.dropTime ? getDateFormat(item.dropTime) : 'Not Available'}</p>
                                                </div>
                                            </div>
                                            <div className='grid grid-cols-2 gap-y-3 px-3'>
                                                <div>
                                                    <p className='text-xs my-0 text-gray-400'>Start Time</p>
                                                    <p className='text-xs font-medium tracking-tight my-0'>{getDateFormat(item.startTime)}</p>
                                                </div>
                                                <div>
                                                    <p className='text-xs my-0 text-gray-400'>End Time</p>
                                                    <p className='text-xs font-medium tracking-tight my-0'>{getDateFormat(item.endTime)}</p>
                                                </div>
                                                <div>
                                                    <p className='text-xs my-0 text-gray-400'>Pickup Time</p>
                                                    <p className='text-xs font-medium tracking-tight my-0'>{item.pickupTime ? getDateFormat(item.pickupTime) : 'Not Available'}</p>
                                                </div>
                                                <div>
                                                    <p className='text-xs my-0 text-gray-400'>Drop Time</p>
                                                    <p className='text-xs font-medium tracking-tight my-0'>{item.dropTime ? getDateFormat(item.dropTime) : 'Not Available'}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </tr> : null}</>
                        })
                    }
                </tbody>
            </table>
        </div>
        {/* { showCreate.status ? <ManageProduct onClose={setShowCreate} onSubmit={onSubmit} edit={showCreate.edit}/> : null} */}
    </>
  )
}

