export const SORT_KEY_RECENT = 'created_at'
export const SORT_KEY_NAME = 'name'

export const SORT_ORDER_ASC = 'asc'
export const SORT_ORDER_DESC = 'desc'
export const LIMIT = 10

export const BOOKING_INITIATED = "initiated"
export const BOOKING_BOOKED = "booked"
export const BOOKING_ONGOING = "ongoing"
export const BOOKING_FINISHED = "finished"
export const BOOKING_CANCELLED = "cancelled"


export const TRANSACTION_TYPE_BOOKING = "booking"
export const TRANSACTION_TYPE_MEMBERSHIP = "membership"